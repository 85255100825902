.e-bigger .custom-button-md.new_style:not(.e-dropdown-popup),
.e-bigger button.e-btn.new_style,
.custom-button-md.new_style:not(.e-dropdown-popup),
button.e-btn.new_style {
    height: 2.5rem;
    min-height: 2.5rem;
    max-height: 2.5rem;
    border-radius: 0.5rem;
    padding: 0 1rem;
    line-height: 2.5rem !important;
    font-size: 1rem;


    &.link-button {
        padding: 0;
        height: auto;
        min-height: auto;
        max-height: auto;
        line-height: 1.5rem !important;
        font-size: 1rem;
        color: var(--content-brand-primary-base) !important;
        background-color: transparent;
        border: 0;

        &.raf_sm {
            font-size: 0.875rem;
            line-height: 1.25rem !important;
            height: auto;
            min-height: auto;
            max-height: auto;
        }

        & .e-btn-icon {
            height: auto;
            min-height: auto;
            max-height: auto;
            line-height: 1.5rem !important;
        }
    }

    &.square-btn {
        width: 2.5rem;
        min-width: 2.5rem;
        max-width: 2.5rem;
    }

    & .e-btn-icon {
        line-height: 2.5rem !important;
        font-size: 1rem;
    }


    &.e-icon-btn {
        width: 2.5rem;
        min-width: 2.5rem;
        max-width: 2.5rem;
        border-radius: 0.5rem !important;

        &.raf_sm {
            width: 2rem;
            min-width: 2rem;
            max-width: 2rem;
            height: 2rem;
            min-height: 2rem;
            max-height: 2rem;
            font-size: 0.8125rem;
        }

        &.raf_xsm {
            width: 1.625rem;
            min-width: 1.625rem;
            max-width: 1.625rem;
            height: 1.625rem;
            min-height: 1.625rem;
            max-height: 1.625rem;
            font-size: 0.75rem;
        }

        & .e-btn-icon {
            line-height: 2rem !important;
            font-size: 0.75rem;
        }
    }

    &.raf_md {
        height: 2.25rem;
        min-height: 2.25rem;
        max-height: 2.25rem;
        line-height: 2.25rem !important;
        font-size: 0.8125rem;

        & .e-btn-icon {
            line-height: 1.5rem !important;
            font-size: 0.8125rem;
        }
    }

    &.raf_38 {
        height: 2.375rem;
        min-height: 2.375rem;
        max-height: 2.375rem;
        line-height: 2.375rem !important;
        font-size: 0.875rem;

        & .e-btn-icon {
            line-height: 1.5rem !important;
            font-size: 0.8125rem;
        }
    }

    &.raf_sm {
        height: 2rem;
        min-height: 2rem;
        max-height: 2rem;
        line-height: 2rem !important;
        font-size: 0.75rem;

        & .e-btn-icon {
            line-height: 1rem !important;
            font-size: 0.75rem;
        }
    }

    &.raf_xsm {
        height: 1.625rem;
        min-height: 1.625rem;
        max-height: 1.625rem;
        line-height: 1.625rem !important;
        font-size: 0.75rem;

        & .e-btn-icon {
            line-height: 0.8125rem !important;
            font-size: 0.75rem;
        }
    }
}

button.e-btn.raf_square_btn {
    height: 2rem;
    width: 2rem;
    min-height: 2rem;
    min-width: 2rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.e-btn {
    &:not(:disabled) {
        &.transparent {
            background-color: transparent;
            border: 0px;
        }

        &.outline {
            background-color: transparent;
            border-color: var(--stroke-neutral-base);
            color: var(--content-neutral-dark);
        }
    }

    &:disabled {
        cursor: not-allowed;
    }
}

.btn_brand_primary,
button.e-btn.btn_brand_primary {

    &:not(:disabled) {
        background-color: var(--surface-brand-primary-light);
        border-color: var(--surface-brand-primary-light);
        color: var(--content-brand-primary-base);
        stroke: var(--content-brand-primary-base);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--primary-hover);
            border-color: var(--primary-hover);
            color: var(--content-neutral-white);
            stroke: var(--content-neutral-white);
        }

        &.semi_dark {
            background-color: var(--surface-brand-primary-semi-dark);
            border-color: var(--surface-brand-primary-semi-dark);
            color: var(--content-neutral-white);
            stroke: var(--content-neutral-white);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--primary-hover);
                border-color: var(--primary-hover);
                color: var(--content-neutral-white);
                stroke: var(--content-neutral-white);
            }
        }

        &.outline {
            background-color: transparent;
            border-color: var(--stroke-primary);
            color: var(--content-brand-primary-base);
            stroke: var(--content-brand-primary-base);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--primary-hover);
                border-color: var(--primary-hover);
                color: var(--content-neutral-white);
                stroke: var(--content-neutral-white);
            }
        }

        &.transparent {
            background-color: transparent;
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-brand-primary-light);
                border-color: var(--surface-brand-primary-light);
                color: var(--content-brand-primary-base);
                stroke: var(--content-brand-primary-base);
            }
        }

        &.white {
            background-color: var(--surface-neutral-light);
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-brand-primary-light);
                border-color: var(--surface-brand-primary-light);
                color: var(--content-brand-primary-base);
                stroke: var(--content-brand-primary-base);
            }
        }
    }
}

.btn_brand_secondary,
button.e-btn.btn_brand_secondary {
    &:not(:disabled) {
        background-color: var(--surface-brand-secondary-light);
        border-color: var(--surface-brand-secondary-light);
        color: var(--content-brand-secondary-base);
        stroke: var(--content-brand-secondary-base);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--secondary-hover);
            border-color: var(--secondary-hover);
            color: var(--content-neutral-white);
            stroke: var(--content-neutral-white);
        }

        &.semi_dark {
            background-color: var(--surface-brand-secondary-semi-dark);
            border-color: var(--surface-brand-secondary-semi-dark);
            color: var(--content-neutral-white);
            stroke: var(--content-neutral-white);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--primary-hover);
                border-color: var(--primary-hover);
                color: var(--content-neutral-white);
                stroke: var(--content-neutral-white);
            }
        }

        &.outline {
            background-color: transparent;
            border-color: var(--stroke-secondary);
            color: var(--content-brand-secondary-base);
            stroke: var(--content-brand-secondary-base);

            &.white {
                background-color: var(--surface-neutral-light);
            }

            &:active,
            &:focus,
            &:hover {
                background-color: var(--secondary-hover);
                border-color: var(--secondary-hover);
                color: var(--content-neutral-white);
                stroke: var(--content-neutral-white);
            }
        }

        &.transparent {
            background-color: transparent;
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-brand-secondary-light);
                border-color: var(--surface-brand-secondary-light);
                color: var(--content-brand-secondary-base);
                stroke: var(--content-brand-secondary-base);
            }
        }

        &.white {
            background-color: var(--surface-neutral-light);
            border: 0px;

            &.outline {
                border: 1px solid var(--stroke-secondary);
                color: var(--content-brand-secondary-base);
                stroke: var(--content-brand-secondary-base);
            }

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-brand-secondary-light);
                border-color: var(--surface-brand-secondary-light);
                color: var(--content-brand-secondary-base);
                stroke: var(--content-brand-secondary-base);
            }
        }
    }

    &_hover {
        &:not(:disabled) {
            & .e-btn-icon {
                color: var(--content-brand-secondary-base);
                stroke: var(--content-brand-secondary-base);
            }

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-brand-secondary-semi-dark);
                border-color: var(--surface-brand-secondary-semi-dark);
                color: var(--content-neutral-white);
                stroke: var(--content-neutral-white);

                & .e-btn-icon {
                    color: var(--content-neutral-white);
                    stroke: var(--content-neutral-white);
                }
            }
        }

    }
}

button.e-btn.btn_brand_tertiary {
    &:not(:disabled) {
        background-color: var(--surface-brand-tertiary-light);
        border-color: var(--surface-brand-tertiary-light);
        color: var(--content-brand-tertiary-base);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--tertiary-hover);
            border-color: var(--tertiary-hover);
            color: var(--content-neutral-white);
        }

        &.semi_dark {
            background-color: var(--surface-brand-tertiary-semi-dark);
            border-color: var(--surface-brand-tertiary-semi-dark);
            color: var(--content-neutral-white);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--primary-hover);
                border-color: var(--primary-hover);
                color: var(--content-neutral-white);
            }
        }

        &.outline {
            background-color: transparent;
            border-color: var(--stroke-tertiary);
            color: var(--content-brand-tertiary-base);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--tertiary-hover);
                border-color: var(--tertiary-hover);
                color: var(--content-neutral-white);
            }
        }

        &.transparent {
            background-color: transparent;
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-brand-tertiary-light);
                border-color: var(--surface-brand-tertiary-light);
                color: var(--content-brand-tertiary-base);
            }
        }

        &.white {
            background-color: var(--surface-neutral-light);
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-brand-tertiary-light);
                border-color: var(--surface-brand-tertiary-light);
                color: var(--content-brand-tertiary-base);
            }
        }
    }
}

button.e-btn.btn_state_warning {
    &:not(:disabled) {
        background-color: var(--surface-state-warning-light);
        border-color: var(--surface-state-warning-light);
        color: var(--content-state-warning-base);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--warning-hover);
            border-color: var(--warning-hover);
            color: var(--content-neutral-white);
        }

        &.semi_dark {
            background-color: var(--surface-state-warning-semi-dark);
            border-color: var(--surface-state-warning-semi-dark);
            color: var(--content-neutral-white);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--warning-hover);
                border-color: var(--warning-hover);
                color: var(--content-neutral-white);
            }
        }

        &.outline {
            background-color: transparent;
            border-color: var(--stroke-warning);
            color: var(--content-state-warning-base);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--warning-hover);
                border-color: var(--warning-hover);
                color: var(--content-neutral-white);
            }
        }

        &.transparent {
            background-color: transparent;
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-state-warning-light);
                border-color: var(--surface-state-warning-light);
                color: var(--content-state-warning-base);
            }
        }

        &.white {
            background-color: var(--surface-neutral-light);
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-state-warning-light);
                border-color: var(--surface-state-warning-light);
                color: var(--content-state-warning-base);
            }
        }
    }

    &_hover {
        &:not(:disabled) {
            & .e-btn-icon {
                color: var(--content-state-warning-base);
                stroke: var(--content-state-warning-base);
            }

            &:active,
            &:focus,
            &:hover {
                background-color: var(--warning-hover);
                border-color: var(--warning-hover);
                color: var(--content-neutral-white);
                stroke: var(--content-neutral-white);

                & .e-btn-icon {
                    color: var(--content-neutral-white);
                    stroke: var(--content-neutral-white);
                }
            }
        }

    }
}

button.e-btn.btn_state_success {
    &:not(:disabled) {
        background-color: var(--surface-state-success-light);
        border-color: var(--surface-state-success-light);
        color: var(--content-state-success-base);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--success-hover);
            border-color: var(--success-hover);
            color: var(--content-neutral-white);
        }

        &.semi_dark {
            background-color: var(--surface-state-success-semi-dark);
            border-color: var(--surface-state-success-semi-dark);
            color: var(--content-neutral-white);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--success-hover);
                border-color: var(--success-hover);
                color: var(--content-neutral-white);
            }
        }

        &.outline {
            background-color: transparent;
            border-color: var(--stroke-success);
            color: var(--content-state-success-base);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--success-hover);
                border-color: var(--success-hover);
                color: var(--content-neutral-white);
            }
        }

        &.transparent {
            background-color: transparent;
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-state-success-light);
                border-color: var(--surface-state-success-light);
                color: var(--content-state-success-base);
            }
        }

        &.white {
            background-color: var(--surface-neutral-light);
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-state-success-light);
                border-color: var(--surface-state-success-light);
                color: var(--content-state-success-base);
            }
        }
    }
}

button.e-btn.btn_state_danger {
    &:not(:disabled) {
        background-color: var(--surface-state-danger-light);
        border-color: var(--surface-state-danger-light);
        color: var(--content-state-danger-base);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--danger-hover);
            border-color: var(--danger-hover);
            color: var(--content-neutral-white);
        }

        &.semi_dark {
            background-color: var(--surface-state-danger-semi-dark);
            border-color: var(--surface-state-danger-semi-dark);
            color: var(--content-neutral-white);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--danger-hover);
                border-color: var(--danger-hover);
                color: var(--content-neutral-white);
            }
        }

        &.outline {
            background-color: transparent;
            border-color: var(--stroke-danger);
            color: var(--content-state-danger-base);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--danger-hover);
                border-color: var(--danger-hover);
                color: var(--content-neutral-white);
            }
        }

        &.transparent {
            background-color: transparent;
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-state-danger-light);
                border-color: var(--surface-state-danger-light);
                color: var(--content-state-danger-base);
            }
        }

        &.white {
            background-color: var(--surface-neutral-light);
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-state-danger-light);
                border-color: var(--surface-state-danger-light);
                color: var(--content-state-danger-base);
            }
        }
    }
}

button.e-btn.btn_neutral {
    &:not(:disabled) {
        background-color: var(--surface-neutral-base);
        border-color: var(--surface-neutral-base);
        color: var(--content-neutral-dark);

        &:active,
        &:focus,
        &:hover {
            background-color: var(--neutral-hover);
            border-color: var(--neutral-hover);
            color: var(--content-neutral-white);
        }

        &.outline {
            background-color: transparent;
            border-color: var(--stroke-neutral-base);
            color: var(--content-neutral-base);

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-neutral-base);
                border-color: var(--neutral-hover);
                color: var(--content-neutral-dark);
            }
        }

        &.transparent {
            background-color: transparent;
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-neutral-base);
                border-color: var(--surface-neutral-base);
                color: var(--content-neutral-dark);
            }
        }

        &.white {
            background-color: var(--surface-neutral-light);
            border: 0px;

            &:active,
            &:focus,
            &:hover {
                background-color: var(--surface-neutral-base);
                border-color: var(--surface-neutral-base);
                color: var(--content-neutral-dark);
            }
        }
    }
}

button.e-btn.hover_rgba {

    &:not(:disabled) {

        &:active,
        &:focus,
        &:hover {
            background-color: rgba($color: #000000, $alpha: 0.05) !important;
            border-color: var(--neutral-hover) !important;
        }
    }
}

.btn_group_container {
    background-color: var(--surface-neutral-base);
    padding: 2px;
    border-radius: 0.5rem;

    & button.e-btn.btn_brand_secondary:not(:disabled),
    & button.e-btn.btn_brand_secondary:not(:disabled):hover,
    & button.e-btn.btn_brand_primary:not(:disabled),
    & button.e-btn.btn_brand_primary:not(:disabled):hover {
        color: var(--content-neutral-dark);
        background-color: var(--surface-neutral-base);
        border-color: transparent;

        &.active {
            background-color: var(--surface-brand-secondary-semi-dark);
            color: var(--content-neutral-white);
        }
    }

    & button.e-btn.btn_brand_primary:not(:disabled),
    & button.e-btn.btn_brand_primary:not(:disabled):hover {
        &.active {
            background-color: var(--surface-brand-primary-semi-dark);
        }
    }
}