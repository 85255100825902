.profile_card {
    &_new {
        // padding: 1rem;
        background-color: #fff;
        border-radius: 0.5rem;
        height: 100%;
    }

    &_details_label {
        color: var(--content-neutral-base);
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1rem;
        text-transform: uppercase;
        font-family: "Montserrat";

        &_normal {
            text-transform: unset;
        }
    }

    &_details_value {
        color: var(--content-neutral-dark);
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.25rem;
    }

    &_text_value {
        color: var(--content-neutral-dark);
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.25rem;
    }

    &_secondary_text_value {
        color: var(--content-neutral-dark);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1rem;
    }

    &__header__text {
        color: var(--content-neutral-dark);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
    }

    &_assignee_col {
        min-width: 110px;
    }

    &_start_col,
    &_end_col {
        min-width: 90px;
        flex: unset;

        &.max_width {
            max-width: 90px;
        }

        // max-width: 110px;
    }

    &_custom_card {
        max-height: 60vh;
    }
    &_header_div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        padding-bottom: 0.5rem;
        &_text {
            padding: 0 2.5rem 0 0;
        }
        &_button {
            position: absolute;
            right: 0;
        }
    }

    &_button_div {
        position: absolute;
        right: 8.5rem;
        top: 6.25rem;
        z-index: 1000;
    }
}

.text_secondary_value {
    font-size: 0.875rem;
    color: var(--content-neutral-base);
    line-height: 1.25rem;
    font-weight: 400;

    &_sm {
        font-size: 0.75rem;
        line-height: 1rem;
    }
}

.raf_list {
    &_item {
        padding: 1rem 0;
        border-bottom: 1px solid var(--stroke-neutral-base);
    }

    &_group {
        & .raf_list_item:last-child {
            border-bottom: 0;
        }
    }
}

.scrollable_header_content {
    position: static;
    width: 100%;
    /* Other styles */
    background-color: var(--surface-neutral);
    transition: all 0.3s ease;
    /* Transition effect for the header */
    z-index: 1000;
    top: -100%;
}

.scrollable_header_content.sticky {
    position: sticky;
    background-color: var(--surface-neutral-light);
    z-index: 1000;
    top: 0;
}

// .sd-element--with-frame {
//     padding: 1.5rem;
// }

@media (min-width: 768px) {
    .profile_card {
        &_details_label {
            font-weight: 500;
        }

        &_details_value {
            font-size: 0.875rem;
        }

        &_start_col,
        &_end_col {
            min-width: 110px;

            &.max_width {
                max-width: 110px;
            }

            // max-width: 110px;
        }
    }
}