// .e-toast-icon.fa,
// .e-toast-icon.far,
// .e-toast-icon.fas,
// .e-toast-icon.fa.e-icon {
//     font-family: "Font Awesome 5 Free" !important;
//     font-weight: 900 !important;
// }

/*//Toast style
.e-success.toast-icons:before {
    content: "\E701";
}

.e-toast-container .e-toast .e-toast-icon {
    font-size: 25px !important;
    margin-right: 15px !important;
}

.custom-toast.e-toast {
    background-color: #fff !important;
    border-radius: 5px !important;
    padding-right: 0 !important;
    padding: 0 !important;
    width: 350px !important;
}

.custom-toast.e-toast .e-toast-icon {
    color: #137c10 !important;
    min-width: 70px;
    height: auto;
    margin-right: 0px !important;
}

.custom-toast.e-toast.e-toast-warning .e-toast-icon {
    color: #d83b01 !important;
}

.custom-toast.e-toast.e-toast-danger .e-toast-icon {
    color: #a80000 !important;
}

.custom-toast.e-toast.e-toast-info .e-toast-icon {
    color: #0378d5 !important;
}

.custom-toast.e-toast.icon-bg-color .e-toast-icon {
    color: #fff !important;
}

.custom-toast.e-toast.icon-bg-color.e-toast-success .e-toast-icon {
    background-color: #137c10 !important;
}

.custom-toast.e-toast.icon-bg-color.e-toast-danger .e-toast-icon {
    background-color: #a80000 !important;
}

.custom-toast.e-toast.icon-bg-color.e-toast-warning .e-toast-icon {
    background-color: #d83b01 !important;
}

.custom-toast.e-toast.icon-bg-color.e-toast-info .e-toast-icon {
    background-color: #0378d5 !important;
}

.custom-toast.e-toast.icon-bg-color .e-toast-icon {
    margin-right: 15px !important;
}

.custom-toast.e-toast .e-toast-close-icon {
    min-width: 70px;
    height: auto !important;
    border-left: 1px solid #f3f3f3;
}

.custom-toast.e-toast .e-toast-close-icon::before {
    content: "Close";
    white-space: nowrap;
    color: #aaa;
    margin-bottom: 6px;
}

.custom-toast.e-toast .e-toast-message {
    padding: 10px;
    padding-left: 0;
}

.custom-toast.icon-bg-color.e-toast .e-toast-message {
    padding-left: 10px;
}

.custom-toast.e-toast.e-toast-success {
    border-left: 5px solid #137c10;
}

.custom-toast.e-toast.e-toast-warning {
    border-left: 5px solid #d83b01;
}

.custom-toast.e-toast-container .e-toast.e-toast-warning .e-toast-icon {
    color: #d83b01 !important;
}

.custom-toast.e-toast.e-toast-danger {
    border-left: 5px solid #a80000;
}

.custom-toast.e-toast.e-toast-info {
    border-left: 5px solid #0378d5;
}
.custom-toast.e-toast.icon-bg-color{
    border-left: 0;
}*/
//Button style
.btn-Default {
    /*background-color: #fff !important;
    border: none !important;*/
    background-color: #f4f4f4 !important;
    border: #f4f4f4 !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, .85) !important;
    font-size: 16px !important;
}

.e-dropdown-btnDefault.btn-Default.e-dropdown-popup {
    background-color: #fff !important;
    border: none !important;
}

.btn-Default.e-active:hover {
    background-color: #ddd !important;
}

.e-dropdown-btnDefault.e-menu-wrapper ul.e-menu.e-menu-parent {
    padding: 0;
}

.e-dropdown-btnDefault.e-menu-wrapper ul.e-menu.e-menu-parent .e-menu-item {
    padding: 0 6px 0 14px !important;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.e-dropdown-btnDefault.e-menu-wrapper.e-caret-hide ul.e-menu.e-menu-parent .e-caret:before {
    content: unset;
}

.btnDefault {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.btnDefault:hover {
    background-color: #eaeaea !important;
    border-color: #eaeaea !important;
    box-shadow: none !important;
}

.e-dropdown-popup ul {
    padding: 0px !important;
}

.btn-defaultBorder {
    background-color: #fff !important;
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
    color: rgba(0, 0, 0, .85) !important;
}

.primary-btn {
    background-color: #1a7bb9 !important;
    border: 1px solid #1a7bb9 !important;
    color: #fff !important;
    box-shadow: none;
    /*font-weight: 400 !important;*/
}

.primary-btn:disabled {
    background-color: #f4f4f4 !important;
    border: 1px solid #f4f4f4 !important;
    color: #a6a6a6 !important;
    box-shadow: none;
    /*font-weight: 400 !important;*/
}

.e-outline.primary-btn {
    background-color: #fff !important;
    border: 1px solid #1a7bb9 !important;
    color: #1a7bb9 !important;
    box-shadow: none;
    /*font-weight: 400 !important;*/
}

.e-outline.primary-btn:hover {
    background-color: #1a7bb9 !important;
    border: 1px solid #1a7bb9 !important;
    color: #fff !important;
    /*font-weight: 400 !important;*/
}

.custom-table .rafSFGrid {
    // height: calc(100vh - 218px) !important;
    // padding: 0.75rem;
}

.page-wrapper.mini-navbar .custom-table .rafSFGrid {
    height: calc(100vh - 90px) !important;
    padding: 0.75rem;
}

.custom-table .rafSFGrid .e-content {
    height: 100% !important;
}

.e-content .form-group {
    margin-bottom: 0px !important;
}


.columnChooser .e-text-wrap {
    color: #A7B1C2;
}

.skyFormField>.rightData>.row .form-group {
    margin-bottom: 0px !important;
}

.conditionFieldListDiv .rightData .row .row {
    margin: unset;
    padding: unset;
    width: 100%;
}

.conditionFieldListDiv .rightData .row .col {
    margin: 0;
}

.conditionFieldListDiv .rightData .row .row:first-child .form-group {
    padding-left: unset;
    padding-right: 15px;
}

.conditionFieldListDiv .rightData .row .row:last-child .form-group {
    padding-left: unset;
    padding-right: unset;
}

.conditionFieldListDiv .rightData .row .form-group {
    padding-left: unset;
    padding-right: unset;
}

.form-group .e-input-group .form-group .e-input-group {
    border-left: 0px;
    border-bottom: 0px;
    border-top: 0px;
}

.form-group .e-input-group .form-group .e-input-group.e-input-focus .e-input {
    padding-left: 0;
}

.form-group .e-input-group .form-group {
    margin-bottom: unset;
    padding-right: unset;
    padding-left: unset;
}

// .form-group .e-input-group .e-input-in-wrap .row {
//     width: 100%;
//     margin: unset;
// }

.rightData .rightData {
    border: unset;
}

/*.e-btn.e-flat {
    font-weight: 400 !important;
}*/

.fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

//Grid Page styles
.related-grid .rafSFGrid {
    border: 0px;
}

.related-grid .rafSFGrid .e-gridheader {
    padding-right: 0 !important;
}

.related-grid .rafSFGrid .e-gridheader .e-headercontent {
    border-left: 1px solid #eaeaea;
}

.related-grid .rafSFGrid table {
    border: 0px;
}

// .related-grid .rafSFGrid.e-grid.e-default table td:first-child {
//     border-left: 1px solid #eaeaea;
// }

// .related-grid .rafSFGrid.e-grid.e-default table td:last-child {
//     border-right: 1px solid #eaeaea;
// }

// .related-grid .rafSFGrid.e-grid.e-default table tr:last-child td {
//     border-bottom: 1px solid #eaeaea;
// }

.related-grid .rafSFGrid.e-grid.e-default table tr.e-emptyrow td {
    border: 0px solid #eaeaea;
}

.gridDefault .e-tooltip.e-control {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.rafSFGrid .e-gridcontent tr.e-row td.e-rowcell {
    cursor: pointer;
    font-size: 13px;
    color: #17191c;
    font-weight: 400;
    // padding: 0.625rem;
}

.rafSFGrid .avatarWithText .avatarSib {
    padding-left: 10px;
}

.rafSFGrid tr.e-columnheader th.e-headercell {
    /*background: #f3f3f3;*/
    background: linear-gradient(180deg, #e6f2ff, #b8d3ed) !important;
    color: rgba(0, 0, 0, .75);
}

.removeHeaderBg .rafSFGrid tr.e-columnheader th.e-headercell {
    background: #fff !important;
}

.rafSFGrid tr.e-columnheader th.e-headercell .e-headertext {
    font-weight: 500 !important;
}

.e-grid .e-filterbarcell {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.e-grid .e-sortfilterdiv {
    margin: -21px -5px !important;
}

.e-input-group:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-input-group.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]),
.e-float-input.e-control-wrapper:hover:not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled),
.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) input:not([disabled]),
.e-float-input.e-control-wrapper:hover:not(.e-input-group):not(.e-success):not(.e-warning):not(.e-error):not(.e-disabled) textarea:not([disabled]) {
    border-color: #0078d6 !important;
}


.rafSFGrid .gridcheckboxclass .e-checkbox-wrapper .e-frame {
    width: 14px;
    height: 14px;
    border: 1px solid #a6a6a6;
    line-height: 13px;
}

.rafSFGrid .gridcheckboxclass .e-checkbox-wrapper .e-check {
    font-size: 10px;
}

.rafSFGrid .gridcheckboxclass .e-checkbox-wrapper .e-stop {
    font-size: 8px;
}

.rafSFGrid .e-headercelldiv .e-headertext {
    font-weight: 500;
    font-size: 12px;
    color: #333;
}

.rafSFGrid .e-columnheader {
    height: 40px;
}

/*
.rafSFGrid .e-row {
    height: 34px;
}

.rafSFGrid.e-grid.e-default.e-bothlines .e-headercell {
    border-bottom-width: 2px;
}*/

/*.rafSFGrid.e-grid .e-control.e-toolbar {
    margin-bottom: 10px;
}*/

/*
.rafSFGrid.e-grid .e-spinner-pane {
    display: none !important;
}
*/

#customSpinner .e-spinner-pane .e-spinner-inner {
    top: calc(50% - 40px) !important;
    transform: unset;
}

.detailsRightSection #customSpinner .e-spinner-pane .e-spinner-inner {
    top: calc(50% - 40px) !important;
    transform: unset;
    position: absolute;
}

.toolbarVisible {
    transform: translateX(0);
    transition: opacity 1s linear, height 1s step-start;
    opacity: 1;
    min-height: 40px !important;
    height: 40px !important;
    margin-bottom: 16px;
}

.toolbarhidden {
    transform: translateX(100%);
    transition: opacity 2s linear, step-end;
    /*transition-timing-function: linear, step-end;*/
    opacity: 0;
    height: 0px !important;
    min-height: 0px !important;
    margin-bottom: 0px;
    border-width: 0 !important;
}


.rafSFGrid.e-grid .e-gridheader .e-headercontent .e-centeralign .e-headercelldiv.e-headerchkcelldiv {
    padding: 0;
}

.rafSFGrid .e-checkbox-wrapper.e-css {
    width: 14px;
    height: 14px;
}

.e-grid .e-gridheader tr th:nth-child(2) span {
    padding-right: 0px;
}

// .rafSFGrid .e-gridheader {
//     padding-right: 0px !important;
// }

.rafSFGrid .e-table {
    width: 100% !important;
}

.e-dropdown-btn.e-btn:focus {
    outline: none !important;
}

// //Tab styles
// .employee-tab-outerDiv {
//     background: #0283d4
// }

// .e-tab.e-fill .e-tab-header {
//     //background: #00adee !important;
//     background: #0283d4 !important;
//     /*border-bottom: 1px solid #2296f4 !important;*/
//     border-bottom: 0px solid #2296f4 !important;
// }

// .details-tab-header.e-tab-header {
//     width: auto !important;
// }

// .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text {
//     font-weight: 400 !important;
// }

// .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
//     border: unset !important;
// }

// .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
//     //background: #00adee !important;
//     background: #0283d4 !important;
//     border-bottom: 2px solid #fff !important;
// }

// .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text {
//     font-weight: 400 !important;
// }


// .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-text {
//     color: #fff;
//     opacity: .7;
// }

// .e-tab.e-fill .e-tab-header .e-toolbar-item:hover .e-tab-text {
//     color: #fff !important;
//     opacity: .9;
// }

// .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text {
//     color: #fff;
//     opacity: 1;
// }

// .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
//     background: #0078d6;
// }

.e-input-group {
    display: flex !important;
}

.fieldErrorMsg {
    color: red;
}

.fieldErrorMsg:before {
    content: '*';
    color: red;
    padding-right: 5px;
    padding-left: 5px;
    font-size: 18px;
}


//Navigation styles
.e-sidebar.sidebar-nav {
    top: 140px;
    //width: 220px !important;
    min-height: calc(100% - 0px);
    //height: 100%;
    background: #0077C0;
    font-size: 13px !important;
    flex-wrap: nowrap !important;
    overflow: auto;
    z-index: 200 !important;
    // cursor: pointer;
    //position: static !important;
    // transform: translateX(0%) !important;
    transition: transform .5s ease;
    visibility: visible !important;
    height: calc(100vh - 130px);
}

.e-sidebar.sidebar-nav.mini-navbar {
    width: 0px !important;
}

.e-sidebar.e-left {
    border-right: unset !important;
}

.e-sidebar.sidebar-nav .e-listview .e-list-item .menu-item {
    opacity: 1;
    padding: 12px 15px;
    color: #fff;
    display: list-item;
}

// .e-sidebar.sidebar-nav .e-listview .e-list-item.e-active,
// .e-sidebar.sidebar-nav .e-listview .e-list-item.e-focused,
// .menu-item>a.active {
//     background-color: #fefefe;
//     color: #0077C0 !important;
//     /*font-weight: 500;*/
//     border-right: 1px solid #e7eaec;
// }

// .e-sidebar.sidebar-nav .e-listview .e-list-item.e-hover .menu-item,
// .e-sidebar.sidebar-nav .e-listview .e-list-item.e-focused .menu-item,
// .e-sidebar.sidebar-nav .e-listview .e-list-item.e-active .menu-item {
//     color: #fff !important;
// }

// .e-sidebar.sidebar-nav .e-listview .e-list-item.e-hover,
// .e-sidebar.sidebar-nav .e-listview .e-list-item.e-active,
// .e-sidebar.sidebar-nav .e-listview .e-list-item.e-focused {
//     background-color: #035b91;
//     border-color: #035b91;

//     //background-color: #fefefe;
//     //color: #0077C0 !important;
// }

.e-sidebar .e-listview .e-list-header {
    background: #0077C0 !important;
    color: #fff !important;
    border: none !important;
    line-height: 36px;
    padding: 0 16px;
    font-weight: 500;
}

.e-sidebar .e-listview .e-icons {
    color: #fff !important;
}

//Top menu styles
.topMenuToolbar.e-toolbar .e-toolbar-items,
.topMenuToolbar .e-menu-wrapper {
    background: transparent;
}

.topMenuToolbar.e-toolbar {
    border: unset;
    background: transparent;
}

.topMenuToolbar .e-menu-wrapper ul.e-menu:not(.e-vertical) {
    padding: 0px;
}

.topMenuToolbar .e-menu-wrapper ul .e-menu-item .e-menu-url {
    color: #fff;
}

.topMenuToolbar .e-menu-wrapper ul .e-menu-item.e-focused {
    background-color: #0c7cd5 !important;
}

.workesio-logo {
    display: flex;
    align-items: center;
    width: 220px;
    color: #fff;
    padding: 0 15px;
}

/*.topNavMenu {
    height: 43px !important;
}*/
.topNavMenu .e-menu-wrapper {
    width: auto;
    /*display: flex;
    flex-wrap: wrap;*/
}

.topNavMenu .e-menu-wrapper ul.e-menu {
    width: auto;
    /*display: flex;
    flex-wrap: wrap;*/
}

.topMenuToolbar.e-toolbar {
    //margin-left: 168px;
    margin-left: 10px;
    height: 42px !important;
}

.topNavMenu.mini-navbar .topMenuToolbar {
    margin-left: 10px;
}

.topNavMenu .toggler {
    background-color: transparent;
    border-color: transparent;
    color: #FFFFFF;
    //padding: 10px 12px;
    margin-left: 7px;
    text-decoration: none;
    font-size: 14px;
    display: block;
    cursor: pointer;
    border-radius: 3px;
    height: 32px !important;
    margin-top: 2px;
    align-items: center;
    justify-content: center;
}

.topNavMenu .toggler.active {
    background-color: #1a7bb9;
}

.topNavBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    width: 100% !important;
    //position: fixed;
    // top: 0;
    position: relative;
    // z-index: 1000;
}

//userprofile
.e-dropdown-popup.userProfile {
    /*left: 1095px !important;
    top: 68px !important;*/
}

.topNavMenuProfile .e-dropdown-btn {
    width: 32px !important;
    height: 32px !important;
    overflow: hidden;
    padding: 3px;
    border-radius: 50% !important;
}

.topNavMenuProfile .e-dropdown-btn:focus {
    outline: unset !important;
}



//Tab styles
.rightTabContent .transactionsTab .e-tab-header {
    display: none;
}

.rightTabContent .e-fill .e-tab-header {
    display: block;
}

.custom-content-toolbar {
    position: absolute;
    right: 0px;
    top: 0px;
    display: inline-flex;
    margin-top: 6px;
}

.e-link-btn {
    padding-right: 17px !important;
    height: 38px !important;
    line-height: 35px !important;
    color: #fff !important;
    border: none !important;
    background: none !important;
    font-weight: 400 !important;
}

.e-link-btn:focus {
    outline: none !important;
    border: none !important;
}

.link-button {
    background-color: transparent;
    border: none !important;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
    font-size: 13px !important;
    font-weight: 400;
    color: #2196f3 !important;
    box-shadow: unset !important;
    text-transform: capitalize;
}

/* Style for disabled link buttons */
.link-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
    text-decoration: line-through !important;

    & * {
        text-decoration: line-through !important;
    }
}

.link-button:hover,
.link-button:focus {
    text-decoration: none;
    outline: unset !important;
}

.transaction-treeview.e-treeview .e-text-content {
    padding-left: 0px;
}

.transaction-treeview.e-treeview .e-list-item.e-active>.e-fullrow {
    /*background-color: #2296f4 !important;
    border-color: #2296f4 !important;*/
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
    border-left: 3px solid #2296f4 !important;
}

.transaction-treeview.e-treeview .e-list-item.e-active>.e-text-content .e-list-text {
    /*color: #fff !important;*/
    color: #333 !important;
    font-weight: 500;
}

.transaction-treeview.e-treeview .e-active .e-icon-expandable,
.transaction-treeview.e-treeview .e-active .e-icon-collapsible {
    color: #fff !important;
    font-size: 16px !important;
}

.rightSectionHeader .e-link-btn {
    line-height: unset !important;
    height: unset !important;
}

//Employee left menu scrollspy style start

.employee-left-menu .scrollspyItems .scrollspyItem a {
    color: #333;
    text-decoration: unset;
}

.employee-left-menu .scrollspyItems .scrollspyItem a div {
    padding: 8px 15px;
    cursor: pointer;
    color: #333;
    font-size: 14px;
    text-decoration: unset;
    width: 100%;
}

.employee-left-menu .scrollspyItems .scrollspyItem:hover a div {
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
}

.employee-left-menu .scrollspyItems .scrollspyItem a.is-active div {
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
    border-left: 3px solid #2296f4 !important;
}

//Employee left menu scrollspy style end

.e-danger.delete {
    position: absolute;
    left: 20px;
}

.overflow-hidden {
    overflow: hidden !important;
}

//Query builder styles
.e-rule-field {
    display: flex;
    align-items: center;
}

.e-rule-value {
    width: auto !important;
}


//New style

.e-multi-line-input .e-input {
    width: 100% !important;
}

.leftSection .form-group,
.outComesHeader .form-group {
    margin-bottom: 0px !important;
}

.leftSection .e-checkbox-wrapper .e-frame,
.outComesHeader .e-checkbox-wrapper .e-frame {
    width: 16px;
    height: 16px;
    border: 1px solid #a6a6a6;
    line-height: 13px;
    border-radius: 2px;
}

.leftSection .e-checkbox-wrapper .e-check .outComesHeader .e-checkbox-wrapper .e-check {
    font-size: 10px;
}


.rightTabContent .panelHeader,
.leftSection .panelHeader,
.riskRegister-outer-container .panelHeader,
.sky_panelHeader {
    background-color: rgb(1, 119, 193) !important;
    color: #fff !important;
    font-weight: 400 !important;
    padding: 0px 20px 0 10px;
    font-size: 16px;
    cursor: pointer !important;
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    height: 38px;
    min-height: 38px;
    justify-content: flex-start;
}
.rightTabContent .panelHeader.sectionHeader {
    padding: 0.5rem 1rem !important;

}

.sky_panelHeader_linear {
    background: linear-gradient(180deg, #e6f2ff, #b8d3ed) !important;
    color: #1e1e1e !important;

    & i.small-icon {
        color: #1e1e1e !important;
    }
}

/*.rightTabContent .outcomesNotSelected .panelHeader {
    background-color: rgba(1, 119, 193, 0.7) !important;
}*/
.rightTabContent .outcomesNotSelected:hover .panelHeader {
    background-color: rgba(1, 119, 193, 1) !important;
}

.rightTabContent .panelHeader .form-group,
.rightTabContent .panelHeader1 .form-group {
    margin-bottom: 0px !important;
}

.leftSection a,
.leftSection a:hover {
    color: inherit !important;
}

.rightTabContent .panelsubHeader {
    background-color: #F7F8F9;
    color: inherit;
    font-weight: 400;
    padding: 0px 0px;
    font-size: 14px;
    cursor: pointer;
    /*border-bottom: 1px solid #ddd;*/
    display: flex;
    align-items: center;
    /*height: 38px;*/
}

.rightTabContent .panelHeader1 .statusValue {
    font-size: 13px;
}

.outComesDiv {
    border-bottom: 1px solid #ddd;
    padding: 0px;
}

.leftSection .outComesDiv .e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
    background-color: #0078d6;
    border-color: #0078d6;
}

.outComesDiv #rafdivIncidentDetails {
    display: none;
}

.outcomes-outter {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
}

.outcomes-outter .related-grid {
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: #fff;
    overflow: auto;
}

.outcomes-outter .related-grid .sectionHeader.panelHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.outcomes-outter .related-grid .collapse.show {
    margin-top: 40px;
}

.outComesHeader {
    cursor: pointer;
    //color: #2296f4;
    color: #666;
    padding: 0.5rem 1rem;
    font-size: 14px;
}

.scrollLogo {
    visibility: hidden;
    margin-left: auto;
    margin-right: 5px;
}

.outComesHeader:hover .scrollLogo {
    visibility: visible;
}

/*.e-content .e-list-parent:nth-child(2) {
    position: fixed;
    left: 0;
    right: 0;
}*/

.outComesHeader:hover,
.outComesHeader.active {
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
    /*font-weight: 500;*/
}

.outcomes-title {
    padding: 8px 20px;
    font-size: 14px;
    font-weight: 400;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: #0177c1 !important;
    border: 1px solid #0177c1;
    cursor: pointer;
}

.outcomes-title .down-arrow {
    border-color: #0177c1;
}

.incidentHeader {
    border-left: 4px solid transparent !important;
    padding: 10px 5px;
    font-weight: 500;
    color: rgb(34, 150, 244);
    cursor: pointer;
}

.incidentHeader:hover {
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
    border-left: 4px solid transparent !important;
    padding: 10px 5px;
    font-weight: 500;
    color: rgb(34, 150, 244);
}

.incidentHeader:active {
    background-color: #f4f4f4 !important;
    border-color: #f4f4f4 !important;
    border-left: 4px solid #2296f4 !important;
    padding: 10px 5px;
    font-weight: 500;
    color: rgb(34, 150, 244);
}

.rightTabContent .panelHeader .e-checkbox-wrapper .e-frame.e-check,
.rightTabContent .panelHeader .e-checkbox-wrapper .e-checkbox:focus+.e-frame.e-check {
    background-color: #FFF;
    border-color: #FFF;
    color: #0078d6;
    font-size: 16px;
    line-height: 18px;
}

.rightTabContent .panelHeader .e-checkbox-wrapper .e-checkbox:focus+.e-frame {
    border: 1px solid #fff !important;
    background-color: #FFF;
}

.rightTabContent .panelHeader .e-checkbox-wrapper .e-checkbox+.e-frame {
    border: 1px solid #fff !important;
    background-color: #FFF;
}


.leftSection {
    //position: absolute;
    max-width: 250px;
    width: 25vw;
    bottom: 10px;
    top: 0px !important;
    overflow: overlay;
    //height: calc(100vh - 262px);
    height: unset;
    //padding: unset;
    background-color: transparent;
    //display: none;
}

// .leftSection.scrolled220 {
//     position: fixed;
//     top: 60px !important;
//     height: unset;
//     margin-top: 15px;
// }

.detailsRightSection {
    /*left: calc( 25vw );
    right: 10px;
    position: relative;
    padding-top: 79px;
    padding-bottom: 16px;
    width: calc(100vw - (30vw + 220px) );
    max-width: calc(100vw - (30vw + 220px) );
    height: auto;*/
}

.detailsRightSection .down-arrow,
.detailsRightSection .right-arrow {
    border-color: #fff;
}

//Risk Score Style Start
.riskScoreDiv {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.riskScoreDiv-item {
    //padding: 0 0 8px;
    background-color: #fff;
    //margin: 10px;
    //margin-right: 15px;
    width: 100%;
    //max-width: 250px;
    //min-width: 25%;
    //color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    //flex-direction: column;
    //margin: 4px;
}

.riskScore-item:first-child {
    border-right: 3px solid #eaeaea;
}

.riskScore-item-header {
    text-align: center;
    line-height: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    padding: 4px 10px;
    width: auto;
    min-width: 200px;
    border-radius: 2px;
    font-size: 14px;
    color: #fff;
    background-color: #20CF6D;
    //margin-bottom: 8px;
    font-weight: 500;
}

.riskScore-item-persentage {
    font-size: 16px;
    font-weight: 700;
    //margin-left: 10px;
    text-align: center;
    line-height: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.riskScore-item-label {
    font-size: 12px;
    opacity: 0.7;
    text-align: center;
    line-height: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.riskScore-SO-Moderate .riskScore-item-header {
    background-color: #2C97DE;
}

.riskScore-high .riskScore-item-header {
    background-color: #F3C501;
}

.riskScore-extreme .riskScore-item-header {
    background-color: #E94B35;
}

.potentialScoreOuterDiv {
    flex-direction: row-reverse;
}

.header-button-divider {
    width: 1px;
    height: 26px;
    background: #bbb;
    margin-right: 20px;
    margin-left: 20px;
}

//Risk Score Style End
/*.mini-navbar .detailsRightSection {
    width: calc(100vw - 30vw );
    max-width: calc(100vw - 30vw );
}*/
//Scrollbar style
.hideScrollBar::-webkit-scrollbar {
    display: none;
}

.customScrollBar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.customScrollBar::-webkit-scrollbar-button {
    display: none;
}

.customScrollBar::-webkit-scrollbar-corner {
    background-color: #fff;
}

.customScrollBar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: solid 3px #fff;
    background-color: #c8c8c8;
}

.customScrollBar.blueCustomScrollBar::-webkit-scrollbar-thumb {
    border: 4px solid #035b91;
}

.customScrollBar::-webkit-scrollbar-track {
    background-color: #fff;
}

/*.e-ddl.e-input-group input[readonly].e-input, 
.e-ddl.e-input-group input[readonly]{
    opacity:0.5;
}*/

//Signaturepad styles

.sigCanvas {
    border: 1px solid #e7eaec;
}

//checklist styles start
.checklistTemplateContainer .form-group,
.checklistContainer .form-group {
    display: flex;
}

.checklist-template-details .details-card .title-header {
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-left: 4px;
}

.checklist-template-details .details-card .form-label,
.checklist-template-details .details-card .form-label-bottom {
    font-size: 11px;
    color: #9e9e9e;
}

.checklist-template-details .details-card .form-label:after {
    content: ":";
    padding-left: 4px;
}

.checklist-template-details .details-card .form-value {
    font-size: 13px;
    color: #212121;
    margin-left: 4px;
}

.checklist-template-details .details-card .form-value-top {
    font-size: 14px;
    color: #212121;
    margin-bottom: 4px;
    font-weight: 600;
}

.checklistTemplateContainer .form-label,
.checklistTemplateContainer .leftLabel,
.checklistContainer .form-label,
.checklistContainer .leftLabel {
    width: 20%;
    text-align: right;
    background: unset;
}

.checklistTemplateContainer .form-group div,
.checklistContainer .form-group div {
    width: 80%;
    margin-left: 15px;
}

.checklistTemplateContainer .form-list-div .form-group div,
.checklistContainer .form-list-div .form-group div {
    margin-left: unset;
}

.checklist-item-create {
    padding: 15px 20px;
    color: #777;
    border-bottom: 2px solid #eee;
    font-size: 12px;
    background-color: #fff;
}

.checklist-item-create .form-group {
    margin: unset;
}

.checklist-item {
    position: relative;
}

.checklist-item-content .checklist-item-header {
    font-size: 15px;
    padding: 10px 15px 15px 15px;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checklist-item-header .form-group {
    margin-bottom: 0 !important;
}

/*.checklist-item:hover {
    background-color: #f9f9f9;
    color: #005ba3;
}*/
.checklist-item.active:after {
    content: "";
    border: 15px solid transparent;
    border-left: 15px solid #fff;
    border-right: unset;
    position: absolute;
    left: 100%;
    top: calc(50% - 15px);
}

.checklist-item-content {
    background-color: #fff;
    height: 100%;
    /*border:1px solid #ddd;*/
}

.form-list-div {
    width: 80%;
    display: flex;
    margin-left: 15px;
}

.form-list-div .form-group div {
    width: 100%;
}

.checklist-inputfield-item:hover .checkbox-value-div {
    display: flex;
}

.checkbox-value-div label {
    margin-top: 7px !important;
}

.checkbox-value-div {
    display: none;
}

.checklistTemplateContainer .panelHeader,
.checklistTemplateContainer .sectionHeader,
.checklistContainer .panelHeader,
.checklistContainer .sectionHeader {
    background-color: #fff !important;
    /*color: rgb(1, 119, 193) !important;*/
    color: rgb(103, 106, 108) !important;
    font-weight: 400 !important;
    padding: 5px 15px !important;
    font-size: 18px;
    cursor: pointer !important;
    border: 1px solid #fff;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 32px;
}

/*.checklistTemplateContainer .sectionHeader i,
.checklistContainer .sectionHeader i {
    color: rgb(1, 119, 193);
}*/

.checklistTemplateContainer .sectionHeader .ui.header,
.checklistContainer .sectionHeader .ui.header {
    color: rgb(103, 106, 108) !important;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
}

.checklist-template-details .page-footer {
    left: 222px;
    right: 0px;
    z-index: 1002;
    bottom: 0;
    position: fixed;
    color: #0D0C22;
    font-weight: 500;
    flex-wrap: nowrap;
    box-shadow: none;
    background-color: #fff !important;
    padding: 10px 20px !important;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0px 1px 0 rgba(0, 0, 0, 0.18) !important;
}

.checklist-template-details .e-card {
    margin-bottom: 30px;
}

.checklist-template-details .e-card-header {
    border-bottom: 1px solid #e7e7e7;
}

.checklist-template-details .e-card-content {
    /*min-height:200px;*/
    padding: 0px;
}

.details-left-section {
    position: relative;
    right: 25vw;
    left: 0px;
    padding-bottom: 50px;
}

.details-left-section .row:focus {
    outline: none !important;
}

.details-right-section {
    position: absolute;
    width: 35vw;
    display: none;
}

.details-right-section.active {
    display: block;
}

.checkListTemplateItemSection {
    background-color: #fff;
    /*padding: 0px 10px 5px 10px;*/
    /*padding: 25px 5px;*/
    padding: 25px 5px 0px 5px;
    /*margin: 4px 5px 4px 10px;*/
    margin: 1px 0px 1px 0px;
    border: 2px solid transparent;
    border-radius: 4px;
    cursor: pointer !important;
    min-height: 70px !important;
}

.checkListTemplateItemSection:hover {
    /*background: #f3f3f3;*/
    border: 2px solid rgb(34, 150, 244);
    border-radius: 4px;
    /*padding: 15px 5px;*/
}

.checkListTemplateItemSection:hover .checklist-item.active:after {
    border-left: 20px solid rgb(34, 150, 244) !important;
}

.checkListTemplateItemSection:focus {
    border: none !important;
    outline: none !important;
}

.checkListTemplateItemSection .form-group {
    margin-top: 5px;
    margin-bottom: 5px;
}

.checkListTemplateItemSection .moreMenu {
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px;
}

.checkListTemplateItemSection .removeItem,
.checkListTemplateItemSection .moreMenu {
    visibility: hidden;
}

.checkListTemplateItemSection:hover .removeItem,
.checkListTemplateItemSection:hover .moreMenu {
    visibility: visible;
}

.checkListTemplateItemSection .dragIcon:hover {
    cursor: move !important;
}

.checkListTemplateItemSection .subFormText {
    cursor: pointer;
    background: #e7eaec;
    border: 1px solid #e7eaec;
    padding: 2px 20px;
    margin-right: -5px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
}

.checkListTemplateItemSection .dropdown-badge {
    width: 130px;
}

.checkListTemplateItemSection .dropdown-badge .e-ddl {
    background: #e7eaec;
    /*border: 1px solid #e7eaec;*/
    border: none;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.checkListTemplateItemSection .dropdown-badge .form-group {
    margin-top: 0px;
    margin-bottom: 0px;
}

//checklist styles end
textarea.e-input:hover:not(.e-success):not(.e-warning):not(.e-error):not([disabled]) {
    border-color: #0078d6 !important;
}


/*Frequency Label Color definitions*/
.lblfrequency {
    font-size: 10px;
    padding: 3px 10px;
    border-radius: 4px;
    font-weight: 500;
    color: #FFFFFF;
    background-color: #CDDC39;
    cursor: pointer;
}

.lblondemand {
    background-color: #FFC107;
}

.lbldaily {
    background-color: #CDDC39;
}

.lblmonthly {
    background-color: #00BCD4;
}

.lblweekly {
    background-color: #7B1FA2;
}

.lblbimonthly {
    background-color: #FF9800;
}

.lblsixmonthly {
    background-color: #FF4081;
}

.lblyearly {
    background-color: #607D8B;
}


/*SK-Spiner style*/
.sk-spinner-double-bounce.sk-spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0 auto;
}

.sk-spinner-double-bounce .sk-double-bounce1,
.sk-spinner-double-bounce .sk-double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #1ab394;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-doubleBounce 2s infinite ease-in-out;
    animation: sk-doubleBounce 2s infinite ease-in-out;
}

.sk-spinner-double-bounce .sk-double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}


.skyFormField .e-file-name {
    color: #333 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 3px 4px 3px 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    //width: 50%;
    white-space: nowrap;
    position: relative;
    top: 5px;
}

.skyFormField .e-file-type {
    color: #333 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.skyFormField .e-file-size {
    color: #676A6C !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    padding: 3px 10px !important;
}

.file-size {
    font-size: 12px;
    padding: 3px 10px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    top: 6px;
}

// //filterMessage styles
// .filterMessageBar {
//     padding: 8px 16px;
//     border: 1px solid transparent;
//     border-radius: .25rem;
//     color: #155724;
//     background-color: #d4edda;
//     border-color: #c3e6cb;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     /*margin-right: 12px;
//     margin-left: 12px;
//     margin-bottom: 1rem;*/
//     margin-bottom: 20px;
//     font-size: 14px;
// }

.clearFilter {
    font-size: 16px;
}

// #employeeTransactionDiv .progressDiv {
//     position:fixed;
// }

.progressDiv {
    top: 0;
    left: 0px;
    position: absolute;
    /*height: 100%;*/
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1500;
    background: #fff;
    opacity: 0.8;
}

.details.row {
    border-bottom: 0.5px solid var(--bs-border-color);
    // border-bottom: 0.5px solid #eee;
}

.details.row .rightData {
    border-bottom: none !important;
}

.details.row .leftLabel {
    border-bottom: 0.5px solid #f9f9f9 !important;
}

.viewFilterPanel .custom-content-toolbar {
    position: absolute;
    right: 0px;
    top: 20px;
    display: inline-flex;
}

.customToggle .fa-icon.active {
    /*color: #1a7bb9;*/
    color: #fff;
}

.customToggle .fa-icon {
    color: #6a6a6a;
}

.groupedBtn {
    background: #edf2f7;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    display: flex;
    /* padding: 2px 5px; */
    width: 75px !important;
}

.groupedBtn .e-radioBtn_0,
.groupedBtn .e-radioBtn_1 {
    padding-left: 5px;
    padding-right: 5px;
}

.groupedBtn .e-radioBtn_0.active {
    background: #1a7bb9;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.groupedBtn .e-radioBtn_1.active {
    background: #1a7bb9;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-left: 5px;
    padding-right: 5px;
}

.e-tab.viewFilterPanel .e-tab-header {
    /*border-bottom: 1px solid #e7e7e7 !important;*/
    padding-left: 15px;
}

.e-tab.viewFilterPanel .e-tab-header .e-toolbar-item.e-active .e-text-wrap::before {
    top: 120%;
}

.e-tab.viewFilterPanel .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 56px;
}

.viewFilterPanel .e-toolbar .e-toolbar-item:hover {
    background: none !important;
    border-color: none !important
}

.sortbySection .form-group {
    margin-bottom: 0px;
}

.viewFilterPanel .e-dropdown-btn {
    padding: 10px;
}

.viewFilterPanel .filterPanel {
    padding: 10px;
    height: calc(100vh - 200px);
    overflow: auto;
    margin-bottom: 0px;
}

.viewFilterName .e-input-group {
    border-color: transparent !important;
}

.viewFilterName .e-input-group.e-input-focus {
    border: 1px solid #0078d6 !important
}

.viewFilterName .e-input-group .e-textbox {
    font-weight: 500 !important;
    font-size: 16px !important;
}

//userProfile card style start
.e-btn.userProfileBtn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    border-width: 0;
    outline: unset !important;
}

.e-card.profile {
    width: 250px;
    z-index: 200;
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    max-height: calc(100vh - 90px);
    justify-content: flex-start;
    overflow-y: auto;
}

.e-card.profile .e-card-header {
    min-height: unset;
    height: auto;
    width: auto;
}

.e-card-header-profile {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
}

.e-card-header-caption.center {
    text-align: center;
}

.e-card.profile .e-card-header .e-card-header-caption .e-card-header-title {
    font-size: 18px;
    font-weight: 500;
}

.e-card.profile .e-card-header .e-card-header-caption .e-card-sub-title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
}

.e-card.profile .e-card-button {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
}

.e-card.profile .e-card-button button {
    border-color: transparent;
    display: block;
    margin-bottom: 5px;
    width: 100%;
    text-align: left;
    padding: 5px 20px;
}

//userProfile card style end
@-webkit-keyframes sk-doubleBounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-doubleBounce {

    0%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/*.divButton {
    border: 1px solid #006fc7;
    padding: 2px 10px;
    border-radius: 2px;
    cursor: pointer;
    text-align:center;
}*/

.divButton .statusValue {
    /*border-bottom: 1px dashed #006fc7;*/
    color: #006fc7;
    cursor: pointer;
    font-weight: 400;
    font-size: 0.875em;
}

.e-control .divButton {
    font-size: 16px;
}

/*
.divButton:hover .statusValue {
   text-decoration: underline;
   text-decoration-color:#006fc7 ;
}
*/
.e-btn-icon.e-icon-dlg-close {
    font-size: 14px !important;
    width: 16px;
}

.e-btn-icon.e-ms-icon {
    background-image: url('./../images/Microsoft_logo.svg');
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    margin-right: 5px;
}

.list-group-item .form-group {
    margin-bottom: 0px;
}

.list-group-item .fieldErrorMsg {
    color: red !important;
}

.badge_notify {
    background: #D1F2EB;
    padding: 3px 10px;
    border-radius: 24px;
    font-size: 12px;
    /*color: #3DC6AB !important;*/
    color: #31a58e !important;
    font-weight: 500;
}

.badge_notify:hover {
    text-decoration: none !important;
}

.businessPermissionGroup .form-group {
    margin-bottom: 0px;
}

.businessPermissionGroup .e-headertext {
    font-size: 12px;
    font-weight: 500;
    color: #333;
}

.businessPermissionGroup .actionBtn {
    visibility: hidden;
}

.businessPermissionGroup .e-table-content {
    cursor: pointer;
}

.businessPermissionGroup .e-table-content:hover .actionBtn {
    visibility: visible;
}

.businessPermissionGroup .e-table-content .actionBtn.active {
    visibility: visible;
}

// .form-label.required:before,
// .e-dlg-body-content .form-label.required:before {
//     content: "*";
//     color: red;
//     padding-right: 5px;
//     font-size: 13px;
// }

.e-dlg-body-content .form-group {
    margin-bottom: 0px;
}

.bg-blue {
    background: #0283d4 !important;
}

//hover style start

// .hover-parent-div .hover-show-child-div {
//     display: none;
// }

// .hover-parent-div:hover .hover-show-child-div {
//     display: block;
// }

//hover style end

//details page panel header
/*.panelHeader.custom{
background-color: #00adee !important;
}*/
.panelHeader.custom .small-icon {
    color: #fff !important;
    font-size: 12px;
}

.leftDetailsSection {
    width: 230px;
    min-width: 230px;
    height: 100%;
    overflow: auto;
    margin-right: 0.75rem;
}

.leftDetailsSection.scrolling-navbar {
    position: fixed;
    width: 230px;
    top: 1rem;
    bottom: 1rem;
    overflow: auto;
}

.rightDetailsSection {
    width: 100%;
    height: 100%;
    // overflow: auto;
    // overflow-x: hidden;
}

.rightDetailsSection .right-section-content-div,
.rightDetailsSection .custom-right-section-content-div {
    height: 100% !important;
    // height: calc(100vh - 286px) !important;
    overflow: auto;
    overflow-x: hidden;
}

.page-wrapper.mini-navbar .rightDetailsSection .right-section-content-div,
.page-wrapper.mini-navbar .rightDetailsSection .custom-right-section-content-div {
    height: calc(100vh - 170px) !important;
}

// .rightDetailsSection .custom-right-section-content-div .rafSFGrid {
//     height: calc(100vh - 160px) !important;
// }

.rightDetailsSection .custom-right-section-content-div .rafSFGrid .e-content {
    height: 100% !important;
    overflow: auto;
}

.rightDetailsSection.scrolling-navbar {
    margin-left: 230px;
    width: calc(100% - 230px);
}

.e-card.employee-id-card {
    width: 450px;
    //min-width: 450px;
    height: 275px;
    padding: 0;
    border-width: 1px;
    border-color: #eaeaea;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.e-card.employee-id-card:hover {
    border-width: 1px;
    border-color: #eaeaea;
}

.e-card.employee-id-card .employee-id-card-header {
    padding: 0.25rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: end;
    background-color: #f0f0f0;
    border-bottom: 1px solid #e0e0e0;
    min-height: 33px;
    width: auto;
}

.e-card.employee-id-card .e-card-content .profileNameDiv {
    color: #333;

}

.e-card.employee-id-card .e-card-content .detailsField-value-div .detailsValue {
    line-height: normal;
    white-space: normal;
    word-break: break-word;
    // display: -webkit-box !important;
    // overflow: hidden;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    // padding-bottom: 3px;
}

.e-card.employee-id-card .employee-id-card-header img {
    width: 75px;
    padding-top: 0.25rem;
}

.e-card.employee-id-card .e-card-content {
    display: flex;
    overflow: visible;
    padding: 1rem;
}

.e-card.employee-id-card .e-card-content img {
    width: 100px;
}

.e-card.employee-id-card .e-card-content img.profileImage {
    width: 100px;
    height: 100px;
}

.e-card.employee-id-card .e-card-content .profile-image-div {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin-top: -35px;
}

.e-card.employee-id-card .e-card-content .profile-qr-code {
    width: 90px;
    height: 90px;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    margin: 1.25rem 0.25rem;
}

.e-card.employee-id-card .e-card-content .profile-qr-code svg {
    position: absolute;
    left: -15px;
    top: -9px;
}

.e-card.employee-id-card .e-card-content .profile-qr-disable-code {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.e-card.employee-id-card .e-card-content .profile-qr-disable-code img {
    width: 60px;
    height: 60px;
    opacity: 0.4;
}

.e-card.employee-id-card .e-card-content .profile-qr-disable-code button {
    line-height: normal;
    padding: 0;
    color: rgb(1, 119, 193);
    background-color: transparent;
    border-color: transparent;
}

.e-card.employee-id-card .e-card-content .profile-qr-disable-code button:hover {
    background-color: transparent;
    border-color: transparent;
}

.profile-qr-disable-code {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    & img {
        position: relative;
        top: 0.25rem;
        opacity: 0.7;
    }
}

.e-card.employee-id-card .e-card-content .profile-image-div img.profile-image {
    width: 100%;
    border-radius: 50%;
}

.e-card.employee-id-card .e-card-content .labelSecondaryText {
    padding-left: 0;
    // display: -webkit-box !important;
    // overflow: hidden;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    // word-break: break-all;
    line-height: normal;
    margin: 0 5px 0 0 !important;
}

.e-card.employee-id-card .e-card-content .detailsField-value-div {
    line-height: normal;
    padding: 0 !important;
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
    line-height: normal;
}

.emp_id_card {
    width: 300px;
    // height: 450px;
    border-radius: 0.5rem;
    box-shadow: var(--bs-box-shadow);
    color: var(--text-block-color);

    &_title {
        color: var(--text-block-color);
    }

    &_secondary_card {
        background-color: #f0f0f0;
        padding: 0.25rem 0.75rem;
        border-radius: 0.25rem;
    }

    &_landscape {
        height: 5.4cm;
        width: 8.6cm;
    }
}

//Scrollbar style
.e-dlg-content::-webkit-scrollbar,
.e-dlg-body-content::-webkit-scrollbar,
.customScrollBar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 4px;
}

.e-dlg-content::-webkit-scrollbar-button,
.e-dlg-body-content::-webkit-scrollbar-button,
.customScrollBar::-webkit-scrollbar-button {
    display: none;
}

.e-dlg-content::-webkit-scrollbar-corner,
.e-dlg-body-content::-webkit-scrollbar-corner,
.customScrollBar::-webkit-scrollbar-corner {
    background-color: transparent;
}

.e-dlg-content::-webkit-scrollbar-thumb,
.e-dlg-body-content::-webkit-scrollbar-thumb,
.customScrollBar::-webkit-scrollbar-thumb {
    border-radius: 9px;
    background-color: #c8c8c8;
}

.e-dlg-content:hover::-webkit-scrollbar-thumb,
.e-dlg-body-content:hover::-webkit-scrollbar-thumb,
.customScrollBar:hover::-webkit-scrollbar-thumb {
    background-color: #999;
}

.e-dlg-content::-webkit-scrollbar-track,
.e-dlg-body-content::-webkit-scrollbar-track,
.customScrollBar::-webkit-scrollbar-track {
    background-color: transparent;
}

.profileCard {
    margin-bottom: 42px;
}

.profileCard .e-card-content {
    display: flex;
    justify-content: space-evenly;
}


.profileCard .e-card-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding: 12px;
}

.e-card-widget .header {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px;
}

.e-card-widget .content {
    font-size: 14px;
    font-weight: 400;
}

.profileCard .e-card-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
}

.e-card-footer .e-custombtn {
    line-height: normal;
    padding: 0;
    color: rgb(1, 119, 193) !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.link-button {
    line-height: normal;
    padding: 0;
    color: rgb(1, 119, 193) !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.profileCard .e-card-content .summaryText {
    justify-content: center;
}

//employee-card style start
.employee-card .e-card-header {
    border-bottom: 1px solid #e0e0e0;
}

.employee-card .e-card-header .e-card-title {
    color: #0283d4;
    font-weight: 500;
}

.custom-card-table .e-gridcontent table {
    display: block;
    padding: 1rem;
}

.custom-card-table table tbody {
    margin: -1rem -0.5rem 0 -0.5rem;
    display: flex !important;
    flex-wrap: wrap;
}

.custom-card-table table tbody tr {
    padding: 1rem 0.5rem 0 0.5rem !important;
    width: 100%;
    border: unset !important;
}

.custom-card-table .e-grid.e-default .e-gridcontent table tbody tr td,
.custom-card-table .e-grid.e-row-responsive .e-gridcontent table tbody tr td {
    width: 100%;
    height: 100%;
    border: 1px solid #e0e0e0 !important;
}

.custom-card-table .rafSFGrid {
    border-width: 0;
}

.custom-card-table .rafSFGrid .e-res-toolbar.e-toolbar {
    border: 1px solid #e0e0e0;
    overflow: hidden;
}

.custom-card-table .rafSFGrid .e-res-toolbar.e-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control {
    max-height: 48px;
    min-height: 48px;
}

// .custom-card-table-1 .e-gridheader { q 
//     display: none;
// }
.employee-card.e-card .e-card-content .labelSecondaryText {
    font-size: 0.625rem;
    padding: 0;
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.employee-card.e-card .e-card-content .detailsValue {
    font-size: 0.8125rem;
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

//employee-card style end


.ecllipseSecondLine,
.ecllipseFirstLine {
    display: -webkit-box !important;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: normal;
    /*padding-bottom: 3px;
    padding-top: 3px;*/
}

.ecllipseFirstLine {
    -webkit-line-clamp: 1;
}

//badge style

.jobtitle-grid-header .collapse-header-title {
    padding-left: 10px;
    width: 100%;

}

.profileCard .e-badge.e-success {
    background-color: #4d841d !important;
}

.profileCard .e-badge.e-default {
    background-color: #e3e3e7 !important;
    color: #333;
}

.raf-badge {
    padding: 0 5px;
    border-radius: 5px;
    font-size: 0.875rem;
    font-weight: 500;
    border: 1px solid #999;
    min-height: 23px;
    color: #1e1e1e;
}

.training-grid-div .sectionHeader {
    padding: 12px 20px 12px 5px;
    background-color: #f3f3f4;
    color: #343a40;
    font-weight: 500;
    cursor: pointer;
}

.training-title {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    line-height: 22px;
}

.training-avatar {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #f0f0f0;
}

.training-status {
    color: #006fc7;
    font-size: 13px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    word-break: break-all;
}

.training-status span {
    font-size: 13px !important;
    font-weight: 500;
    word-break: break-all;
}

.e-bigger .e-card.training-card .e-card-content {
    padding: 0.75rem;
}

.e-card.training-card .labelSecondaryText {
    font-size: 10px !important;
}

.e-card.training-card .detailsValue {
    font-size: 13px !important;
}

.e-card.training-completed .training-avatar {
    background-color: #6dce5b;
    color: #fff;
}

.e-card.training-completed .training-status {
    color: #6dce5b;
}

.e-card.training-expired .training-avatar {
    background-color: #ee0411;
    color: #fff;
}

.e-card.training-expired .training-status {
    color: #ee0411;
}

.e-card.training-inProgress .training-avatar {
    background-color: #006fc7;
    color: #fff;
}

.e-card.training-in.progress .training-status {
    color: #006fc7;
}

.training-icon {
    font-size: 18px;
    color: #fff;
}

.progressBody {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1500;
}

.customViewDropdownBtn.e-btn {
    //background: #fff !important;
    //border: 1px solid #e7e7e3 !important;
    padding: 5px 15px;
    min-width: 300px !important;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-right: 0.5rem;
}

.customViewDropdownBtn.e-btn .e-btn-icon {
    margin-top: 0;
}

.e-popup .customViewDropdownBtn {
    width: 100%;
}

.customViewDropdownBtn.e-popup-open {
    padding: 0px !important;
    box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    max-width: calc(100vw - 30px);
    min-width: 300px;
}

.customViewDropdownMenu {
    background: #fff;
}

.customViewDropdownMenu .dropdown-item {
    padding: 10px 25px;
    font-weight: 400;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.customViewDropdownMenu .dropdown-item:hover {
    color: #0078D7 !important;
    background-color: #f4f4f4 !important;
}

.customViewDropdownMenu .dropdown-item.active {
    background: #DCECF9 !important;
    font-weight: 500;
    color: #0078D7;
}

.customViewDropdownMenu .dropdown-item .e-icons {
    color: #666;
}

.customViewDropdownMenu .dropdown-item.active .e-icons {
    color: #0078D7;
}

.customViewDropdownMenu .dropdown-item-header {
    padding: 10px 15px;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    background: #F4F5F6;
}

.customViewDropdownMenu .dropdown-item-header .fa-icon {
    font-size: 13px;
    color: #999 !important;
    width: 12px;
}


.customViewDropdownMenu .dropdown-item-header.active .down-arrow:before {
    transform: rotate(45deg);
    top: calc(50% - 6px);
    left: 0;
}

.customViewDropdownMenu .dropdown-item-header .down-arrow:before {
    transform: rotate(-45deg);
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    left: 0;
}

.px-2-5 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.headerIconDiv {
    height: 2rem;
    width: 2rem;
    line-height: 2rem;
    vertical-align: middle;
    text-align: center;
    background-color: #e0e0e0;
    font-size: 1rem;
    color: #333;
    border-radius: 4px;
}

//Navigation tree view style

// .e-sidebar__treeview__logo {
//     width: 140px;
//     max-width: 200px;
//     height: auto;
//     margin-top: 8px;
// }


@media only screen and (min-width: 576px) {
    .custom-card-table table tbody tr {
        width: 50%;
    }
}

@media only screen and (max-width: 992px) {
    .customViewDropdownBtn.e-btn {
        min-width: 250px !important;
    }
}

@media only screen and (min-width: 1200px) {
    .custom-card-table table tbody tr {
        width: 33%;
    }
}

@media only screen and (min-width: 1320px) {
    .custom-card-table table tbody tr {
        width: 25%;
    }
}

@media only screen and (min-width: 767px) {
    .scrollspyItemDiv .shadow {
        box-shadow: none !important;
    }
}

@media only screen and (max-width: 767px) {
    .e-sidebar.sidebar-nav {
        width: 0px !important;
    }

    .e-sidebar.sidebar-nav.mini-navbar {
        width: 220px !important;
        z-index: 1034 !important;
        top: 0px !important;
        position: fixed !important;
    }

    .e-sidebar.sidebar-nav.sidebar-lg-nav.mini-navbar {
        width: 220px !important;
        z-index: 1034 !important;
        top: 0px !important;
        position: fixed !important;
        display: block;
        height: 100%;
    }

    .e-sidebar.sidebar-nav.sidebar-sm-nav.mini-navbar {
        display: none;
    }

    //userprofile
    .e-dropdown-popup.userProfile {
        /*left: 264px !important;*/
    }

    .leftSection {
        width: auto;
        max-width: 100vw;
        position: static;
        flex: 0 0 100%;
        z-index: 2;
        left: 0px;
        right: 0px;
        top: 0;
        background-color: #f3f3f4;
        bottom: unset;
    }

    // .leftSection.scrolled220 {
    //     padding: 25px;
    //     margin-top: 0;
    // }

    .detailsRightSection {
        /*width: calc(100vw - ( 25vw + 55px ));
        max-width: calc(100vw - ( 25vw + 55px ));*/
        width: 100%;
        max-width: 100%;
        position: static;
        flex: 0 0 100%;
    }

    .topMenuToolbar {
        margin-left: 15px;
    }

    .outComesHeader .scrollLogo {
        visibility: hidden;
    }

    // .scrollspyItemDiv {
    //     position: absolute;
    //     height: 65vh;
    //     min-height: 200px;
    //     //overflow: auto;
    //     width: 100%;
    //     padding-right: 50px;
    //     //box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0px 1px 0 rgba(0, 0, 0, 0.18) !important;
    //     //box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    // }

    // .leftSection.scrolled220 .scrollspyItemDiv {
    //     position: fixed;
    // }

    .detailsRightSection.outcomeVisible {
        opacity: 0.7;
    }

    .detailsLeftSection .dropdown-section-div {
        padding-bottom: 25px;
    }

    .detailsLeftSection.scrolled220 .dropdown-section-div {
        padding-right: 48px;
        background-color: #f3f4f5;
        margin-left: -25px;
        padding-top: 20px;
    }

    .detailsLeftSection.scrolled220 .dropdown-section-div .outcomes-title {
        margin-left: 25px;
    }

    .topNavMenu {
        justify-content: space-between;
    }

    .actionMenu-outer-div {
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        z-index: 1050;
        width: 300px;
        right: 0;
        padding: 15px;
        transform: translate(100%, 0);
        transition: all 0.5s ease-in-out;
        overflow: auto;
    }

    .actionMenu-outer-div.active {
        transform: translate(0%, 0);
    }

    .actionMenu-outer-div.active .e-toolbar-center {
        margin-left: 0 !important;
    }

    .actionMenu-outer-div .e-menu-wrapper ul.e-menu {
        display: flex;
        flex-direction: column;
    }

    .actionMenu-outer-div .e-menu-wrapper ul.e-menu li .e-menu-url {
        color: #2296f4;
        font-size: 14px;
    }

    .topNavMenu .left-arrow,
    .topNavMenu .down-arrow {
        border-color: #fff !important;
    }

    .topNavMenu .toggler {
        display: none;
    }

    .topNavMenu.scrolled220 .toggler {
        display: flex;
    }

    /*.leftLabel {
        justify-content: flex-start !important;
        padding-top:8px !important;
        padding-bottom:8px !important;
    }*/

    .padding-start-0 {
        padding-left: 0px !important;
    }

    .card-topbar-div {
        position: relative;
    }

    .card-topbar-div .profileImg-container {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
    }

    .card-topbar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 30px;
        background-color: #e0e0e0;
        border-bottom: #e0e0e0;
    }

    .customViewDropdownBtn.e-btn {
        min-width: 300px !important;
    }
}

@media only screen and (max-width: 576px) {
    .conditionFieldListDiv .rightData .row .col {
        flex-direction: column;
        margin: 0;
    }

    .conditionFieldListDiv .rightData .row .row:first-child .form-group {
        padding-right: unset;
        padding-bottom: 15px !important;
    }

    .riskScore-item-header {
        min-width: 100px;
    }

    .riskScoreDiv-item {
        flex-direction: column;
    }

    .customViewDropdownBtn.e-btn {
        min-width: 100% !important;
        max-width: calc(100vw - 130px);
    }
}

@media only screen and (max-width: 370px) {
    .e-card.profile {
        width: 90vw;
    }
}

@media only screen and (min-width: 1600px) {
    .riskScoreDiv-item:first-child {
        margin-right: 5%;
    }
}